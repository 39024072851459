  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

  body {
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #ffff;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  a{
    color: #1e9bdd;
  }


  header {
    background: #FFFFFF;
    box-shadow: 0px 0px 30px rgb(204 204 204 / 25%);
    padding: 10px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
  }

  header h1 {
    margin-bottom: 0;
    height: 45px;
    float: left;
  }

  header h1 svg {
    height: 45px;
    float: left;
    position: relative;
    top: 0;
  }

  header ul.topNav {
    float: left;
    list-style: none;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    height: 45px;
    margin: 0;
  }

  header ul.topNav li a {
    margin: 0 10px;
    cursor: pointer;
    color: #000000;
    text-decoration: none;
  }

  header ul.topNav li a:hover {
    color: #1e9bdd;
  }

  .terms_Privacy_Page {
    position: relative;
    top: 80px;
    padding-bottom: 80px;
    margin-top: 20px;
  }

  p {
    margin: 0 !important;
    font-size: 15px;
    line-height: 30px;
  }

  .privacy_area_body {
    padding: 15px 0;
}

.privacy_area h1 {
  margin-top: 0;
  padding: 0 0 10px 0;
  display: inline-block;
  font-size: 32px;
}

.privacy_area .mt-40 {
  margin-top: 40px;
}

  .privacy_area_body .col-md-8 .text-section {
    padding-top: 70px;
}

.privacy_area .text-section label {
  color: #333;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 12px;
  display: block;
  clear: both;
  text-transform: capitalize;
}

ul.privacy-links.left-links {
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #eeeeee;
}

ul.privacy-links.left-links li {
  list-style: none;
  padding: 5px 0;
}

.privacy_area .left-links li a {
  color: #1e9bdd;
  font-size: 15px;
  text-transform: capitalize;
 text-decoration: none;
}


footer {
  background: #171717;
  color: #fff;
  padding: 15px 0;
  width: 100%;
  position: relative;
  bottom: 0;
  position: fixed;
}

 h3.mobile {
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  float: left;
  padding: 0;
  margin: 0;
}

.footerNav{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
}



.footerNav li a{
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

@media only screen and (min-width: 300px) and (max-width: 767.99px) {

  header ul.topNav,
  .footerNav{
    padding: 0;
    justify-content: space-between;
  }

  header ul.topNav li a,
  .footerNav li a {
    font-size: 14px;
    margin: 0 5px;
}

h3.mobile{
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
}

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  header ul.topNav,
  .footerNav{
    padding: 0;
    justify-content: space-between;
  }

  .footerNav li a {
    font-size: 12px;
    margin: 0 5px;
}
}